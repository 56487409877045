.Toastify__toast {
    background-repeat: no-repeat;
    background-position: 15px center;
}

:root {
    --toastify-color-info: #2f96b4;
    --toastify-color-success: #51a351;
    --toastify-color-warning: #f89406;
    --toastify-color-error: #bd362f;
}

.Toastify__toast-container {
    width: inherit;
}

.Toastify__toast-container--top-right {
    top: 50px;
}

.Toastify__toast-container pre {
    color: inherit;
}
