.rse-searchbar {
    position: fixed;
    top: 5px;
    left: 210px;
    width: 30em;
    z-index: 4;
}

.rse-searchbar input {
    padding: 0.375rem 0.75rem;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
}

.main-bloc.rse {
    width: 20em;
    margin-top: 54px;
    margin-bottom: 16px;
    overflow: auto;
}

.wp-search-bar {
    width: 20em;
}
