.tire-label {
    color: #225572;
    margin-right: 5px;
}

.tire-ean {
    color: green;
}

.tire-symbols > span {
    margin-right: 10px;
}

.tire-symbols > span > svg {
    margin-right: 5px;
}

.tire-index-A { color: #00AF5F }
.tire-index-B { color: #58C258 }
.tire-index-C { color: #CBDB37 }
.tire-index-D { color: #FFF302 }
.tire-index-E { color: #FFC60E }
.tire-index-F { color: #FB8528 }
.tire-index-G { color: #F62F27 }
