.identification {
    max-width: 22em;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.modal-body > .identification {
    max-width: none;
    margin-top: 0;
}

.identification.hide-bloc {
    display: none;
}
.modal-body > .identification.hide-bloc {
    display: flex;
}

.identification > form > .star {
    color: #fc8500;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 10px;
}

.country-led {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 100%;
    position: absolute;
    bottom: 12px;
    right: 11px;
}

.immatriculation > .plate-buttons {
    display: flex;
    align-items: center;
}

.immatriculation > .plate-buttons > .btn-none {
    padding: 5px;
}

.swiss-plate {
    background-image: url("https://medias.ia.parts/images/swissPlate.jpg");
    background-repeat: no-repeat;
    width: 240px;
    height: 58px;
    margin: auto;
    position: relative;
}

.swiss-plate > input {
    background: none;
    border: none;
    height: 50px;
    font-size: xx-large;
    width: 175px;
    text-align: center;
    margin-left: 30px;
    outline: none;
}

.swiss-plate > .btn-none {
    position: absolute;
    bottom: 0;
    right: -55px;
}

.vehicle-row-content {
    height: 50px;
    background: linear-gradient(90deg, #fff, #a7a5a6);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 0 8px 8px 0;
    flex-grow: 1;
    display: flex;
    padding-left: 8px;
    padding-right: 16px;
    align-items: center;
    justify-content: space-between;
}

.vehicle-row-brand {
    border: 3px solid #e67d09;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
}