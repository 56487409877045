.breadcrumb > .Select {
    flex-grow: 1;
    margin-left: 10px;
}

.breadcrumb > .genart-search {
    margin-right: 100px;
}

.select-option:hover {
    background-color: cyan;
}

.v3 .bloc-with-albums {
    margin-top: 50px;
}