.v2 .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    right: 3%;
    width: 70%;
    height: 70%;
}

.v2 h2 {
  padding-bottom: 1em;
  margin-top: 0px;
  margin-bottom: 0px;
	text-align: left;
	color: #e78419;
}

/* Custom Arrow */
.v2 .prev {
  color: #999;
  position: absolute;
  top: 38%;
  left: -1em;
  font-size: 1.5em;
}
.v2 .prev :hover {
  cursor: pointer;
  color: black;
}

.v2 .next {
  color: #999;
  position: absolute;
  top: 38%;
  right: -2em;
  font-size: 1.5em;
}
.v2 .next :hover {
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 800px) {
.v2 .next {
    display: none !important;
  }
}