.idcar-panel {
    position: absolute;
    right: 0;
    top: calc(20% + 100px);
    z-index: 2;
}

.idcar-panel > button {
    display: inline-block;
    position: absolute;
    left: -77px;
    top: 84px;
    padding: 5px;
    padding-right: 10px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    background-color: #ffffff;
    border: 1px #1e2335 solid;
    border-right: 1px #fff solid;
    width: 77px;
}

.idcar-content {
    display: inline-block;
    position: relative;
    background: white;
    margin-top: -20px;
    padding: 20px;
    border-radius: 6px 0 0 6px;
    width: 40em;
    overflow-y: auto;
    border: 1px #1e2335 solid;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.idcar-content > h3 {
    color: #f78300;
}

.idcar-cb {
    border: 1px solid #dddbdb;
    border-radius: 5px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    padding: 5px;
    justify-content: center;
    align-items: center;
    max-width: 475px;
    height: 268px;
    position: relative;
    flex-grow: 1;
    background-size: contain;
    background-image: url(https://medias.ia.parts/idcar/cb-background.jpg);
}

.idcar-cb-sivince {
    position: absolute;
    bottom: 8px;
    right: 17px;
}

@media (max-width: 635px) {
    .idcar-cb-sivince {
        display: none;
    }
}

.idcar-public {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.idcar-ios-wallet {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 50px;
}

.idcar-android-wallet {
    align-self: start;
    width: 120px;
    margin-left: 3px;
}

.pkpass-images {
    height: 50px;
}
