.app {
    height: 100%;
}

a {
    text-decoration: none;
}

.username {
    color: white;
    align-self: center;
    display: flex;
}

.username > span {
    margin-right: 5px;
}

.username.name-reversed {
    flex-direction: row-reverse;
}

.navbar-left,
.navbar-right {
    position: absolute;
    z-index: 1;
    border-bottom: 2px #fa8400;
    border-bottom-style: outset;
}
.navbar-left {
    display: flex;
    align-items: center;
}

.navbar-right {
    right: 0;
    top: 0;
    padding: 5px;
    border-image: linear-gradient(
            to left,
            rgb(30, 35, 55) 1%,
            rgb(250, 132, 0) 50%,
            rgb(30, 35, 55) 100%
        )
        100% 0 100% 0/3px 0 3px 0 stretch;
    height: 50px;
}
.navbar-right > .dropdown > button {
    padding: 2px;
    width: 30px;
    border-radius: 100%;
}
.navbar-right > .dropdown {
    display: inline-block;
}
.navbar-right > button {
    margin-right: 1em;
    padding: 3px;
}

.navbar-right .btn {
    background-color: #1e2337;
    color: white;
}

.narrow-dropdown {
    min-width: 3em;
}

.caia-logo {
    cursor: pointer;
}

.caia-logo > img {
    padding: 5px;
}

.main-bloc {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px;
    align-self: flex-start;
    max-height: calc(100vh - 150px);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    position: relative;
}

.content-box {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    position: relative;
}

.btn.switch-vehicle {
    color: white;
    margin-left: 10px;
}

.btn-diag {
    margin-left: 10px;
    color: white;
    background-color: #3cad59;
}

.small-logo {
    width: 40px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40px;
    margin-bottom: 55px;
    margin-right: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 20px 5px 20px 0px;
    transition: width 0.4s;
    overflow: hidden;
    flex-shrink: 0;
    overflow-y: auto;
    z-index: 1;
}
.sidebar.extended {
    width: 13em;
}

.sidebar .btn-img {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f8fa;
    border-radius: 5px;
    box-shadow: 3px 3px 2px gray;
    line-height: 10px;
    margin-bottom: 10px;
}
.sidebar .btn-img + span {
    margin-left: 8px;
    white-space: nowrap;
}
.sidebar i {
    font-size: 1em;
}

.sidebar > a {
    color: black;
    display: flex;
}

.main-content {
    display: flex;
    height: 100vh;
    background-color: #1e2337;
    background-image: url("../img/logo_caia.png");
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.marque {
    display: inline-block;
    margin: 5px;
}

.marque.dragging {
    cursor: grabbing;
    pointer-events: none !important;
}

.marque > img {
    display: block;
    width: 70px;
    height: 70px;
    object-fit: contain;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
    transition: box-shadow 0.25s ease 0s;
}
.marque > img:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 12px 24px -6px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

.app-button-img {
    padding: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
    transition: box-shadow 0.25s ease 0s;
}

.marque > .app-label {
    display: none;
}

.marque.new-app {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
    transition: box-shadow 0.25s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    isolation: isolate;
}

.marque.new-app:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 12px 24px -6px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

.app-button {
    position: relative;
    margin-bottom: 10px;
}

.app-button > .badge {
    position: absolute;
    bottom: -10px;
    left: 5px;
    background-color: #007bff;
}

.bottom-badge {
    position: absolute;
    bottom: -10px;
    left: 5px;
    z-index: 1;
}

.selection-brand .marque > img,
.social-network-apps .marque > svg {
    width: 70px;
    height: 70px;
    display: block;
    object-fit: contain;
}

.selection-bloc {
    margin-top: 0;
    height: calc(100vh - 175px);
    overflow-y: auto;
}
.carselector-fullscreen .selection-bloc {
    height: 100vh;
}
.with-short-bloc > .selection-bloc {
    flex-grow: 1;
}
.selection-bloc.select-engine {
    width: 67em;
}

.iframe-wrapper > .stack-slider .selection-bloc {
    width: 100%;
}

.selection-brand {
    width: 30em;
}

.car-selector-search {
    width: 29em;
    margin-left: 10px;
}
.car-selector-search > input {
    border-radius: 20px;
    width: 29em;
}

.car-selector-search:after {
    content: "";
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: white transparent;
    margin: auto;
}

.main-brands {
    display: flex;
    flex-wrap: wrap;
}

.brand-buttons.brand-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.brand-buttons .btn {
    color: #1e2337;
    border-color: #1e2337;
    white-space: inherit;
    background-color: #fff;
}

.brand-buttons .btn:hover {
    border-color: #ee7e05;
}

.slider-wrapper {
    width: calc(100% - 7em);
    display: inline-block;
    margin-left: 13px;
    margin-right: 13px;
}

.hidden {
    display: none !important;
}

.menu-toggler {
    color: white;
    padding: 6px 0 0 5px;
}
.menu-toggler:hover {
    color: #fc8500;
}

.immatriculation {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 4px;
}

.immat-wrapper {
    background-image: url("../img/immat.jpg");
    height: 58px;
    width: 240px;
    flex-shrink: 0;
}

.immatriculation > .immat-wrapper > #immat {
    height: 40px;
    width: 173px;
    margin-top: 8px;
    margin-left: 32px;
    font-size: 2em;
    border: 0 none;
    background-color: transparent;
    text-align: center;
    outline: none;
}

.tooltip-content {
    color: black;
    background-color: #fff;
    width: 25em;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    box-shadow: inset 2px 2px 20px 0 #656565;
    border: 1px solid #1e2337;
    margin-top: 10px;
    margin-left: 15px;
}

.tooltip-content:before,
.tooltip-content:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f5f8fa;
    position: absolute;
    top: -10px;
    left: 8em;
}

.tooltip-content > ul {
    overflow-y: auto;
    height: 15em;
    list-style-type: none;
    padding-left: 0;
}

.tooltip-content:before {
    left: 67px;
    border-bottom: 10px solid #1e2337;
    top: -11px;
}
.tooltip-content:after {
    left: 67px;
    z-index: 1;
}

.bookmark-list:before,
.bookmark-list:after {
    left: 305px;
}

.history-list:before,
.history-list:after {
    left: 89px;
}

.indent {
    padding-left: 20px;
}

.tooltip-content .cadre {
    position: absolute;
    width: 80px;
    height: 80px;
    border: 1px solid gray;
    top: 30px;
    right: 30px;
}

.other-brands .marque {
    border: 1px solid gray;
    padding: 2px;
    width: 5em;
    height: 2em;
    display: inline-block;
}

.language-selector > img {
    width: 1.5em;
    cursor: pointer;
}

.other-brands {
    height: 16em;
    border: 1px solid #1e2337;
    border-radius: 5px;
}

.bloc-image {
    position: absolute;
    top: 20px;
    right: 60px;
}

.spinner {
    color: white;
    vertical-align: middle;
    margin-left: 5px;
}

.iframe-wrapper {
    margin-top: 50px;
    width: 100%;
    height: calc(100% - 140px);
}

.iframe-wrapper.iframe-carselector-withtabs {
    width: inherit;
}

.iframe-wrapper.fullscreen-iframe {
    height: calc(100% - 60px);
    width: 100%;
}

.iframe-wrapper iframe {
    width: 100%;
    height: calc(100% - 10px);
    background-color: white;
}

.iframe-wrapper._3D {
    position: fixed;
    z-index: 1;
    background: rgba(30, 35, 55, 0.9);
    height: calc(100% - 50px);
}

.inner-content {
    display: flex;
    margin-top: 50px;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    isolation: isolate;
}

.with-short-bloc {
    display: flex;
    flex-grow: 1;
}

.tabs {
    flex-grow: 1;
    padding: 5px 0 85px 5px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.tabs > .nav-tabs {
    position: relative;
}

.tabs > .tab-content {
    display: flex;
    flex-grow: 1;
    position: relative;
}

.tabs > .tab-content > .active {
    display: flex;
}

.tabs .tab-pane {
    flex-grow: 1;
}

.nav-tabs .nav-link {
    background-color: gray;
}

.tab-pane > .iframe-wrapper {
    margin-top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

.item-bloc ul {
    list-style-type: none;
    padding-left: 0;
}

.oem-list.item-bloc ul {
    list-style-type: revert;
    padding-left: revert;
}

.gui-selector-bloc ul ul {
    padding-left: 40px;
    list-style-type: disc;
}

.gui-selector-bloc .item-image {
    width: 40px;
    margin-right: 5px;
}

.copyright {
    position: fixed;
    bottom: 5px;
    right: 0;
    width: 20em;
    font-size: 12px;
    color: #333848;
}

.modal-dialog.diag-modal {
    max-width: calc(100vw - 200px);
}

.diag-modal > .modal-content {
    height: calc(100vh - 200px);
    background-color: #1e2337;
}

.diag-modal .modal-header {
    color: white;
    border-bottom-color: #1e2337;
}

.diag-modal .modal-body {
    display: flex;
}

.diag-modal iframe {
    width: 100%;
}

.select-part-brand {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tire-suppliers img {
    width: 85px;
    margin: 1px;
}

.tire-suppliers > form > div {
    flex-grow: 1;
}

.iframe-partselector,
iframe-carselector {
    flex-grow: 1;
}

.toggle-fullscreen {
    color: white;
    display: inline-block;
    cursor: pointer;
}

.title-iframe {
    display: inline-block;
    color: white;
    position: absolute;
    top: 10px;
    left: 33rem;
    font-size: 1.2em;
}

.tabs.tabs-carselector {
    padding-top: 0;
}

.tabs.tabs-carselector > .nav-tabs {
    position: absolute;
    top: 18px;
    left: 25em;
}

.tabs .nav-link > img {
    display: none;
}

@media (max-width: 1600px) {
    .tabs .nav-link > span {
        display: none;
    }
    .tabs .nav-link {
        padding: 5px;
    }
    .tabs .nav-link > img {
        display: inline-block;
        height: 35px;
    }
}

.my-account {
    flex-grow: 1;
    color: white;
    margin-top: 50px;
    margin-left: 10px;
}

.ombre-haut2 {
    position: absolute;
    top: 47px;
    height: 20px;
    width: 100%;
    z-index: 0;
}
.ombre-bas2 {
    position: absolute;
    bottom: 50px;
    height: 20px;
    width: 100%;
    z-index: 0;
}

.oes-slider {
    position: fixed;
    width: 45%;
    margin-left: 20px;
    margin-right: 20px;
    right: 5%;
    top: 200px;
    bottom: 0;
    overflow: hidden;
    z-index: 3;
}

.oes-slider .selection-bloc {
    width: inherit;
    height: 100%;
    flex-grow: 1;
}

.oes-slider .search-item-container .oes-table {
    max-height: none;
}

.nav-tabs .nav-link.nav-link {
    border: 1px solid #e2e2e2;
    color: #e2e2e2;
    background-color: #08080800;
}

.nav-link > a {
    color: #e2e2e2;
}

.nav-tabs .nav-link.nav-link:focus,
.nav-tabs .nav-link.nav-link:hover,
.nav-link.active > a:hover {
    color: #fc8500;
}

.nav.nav-tabs .nav-link.active {
    color: #1e2337;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-link.active > a {
    color: #1e2337;
}

a,
.nav-link {
    color: #1e2337;
}

a:hover,
.nav-link:hover,
a:focus {
    color: #f38102;
}

.tires-table {
    min-height: 300px;
    height: calc(100vh - 420px);
}

.badge-primary {
    background-color: #f38102;
    margin-left: 2px;
}

.select-above-table {
    z-index: 11;
}

.create-actia-session-toast > .logo-actia {
    width: 60px;
    margin-right: 10px;
}

.img-button {
    border: none;
    background-color: transparent;
}

.cart-link {
    color: white;
    margin-right: 10px;
}

.languages {
    display: flex;
    flex-wrap: wrap;
}

.languages > div {
    width: 110px;
    height: 110px;
    margin: 6px;
}

.languages > div > .btn {
    width: 80px;
    height: 80px;
    box-shadow: 1px 1px gray;
    border-radius: 16px;
    border: 1px solid black;
    display: block;
    font-size: 32px;
}

.languages > div > .btn:hover {
    background-color: #6c757d;
}

.account-username {
    color: orange;
}

.select-model {
    margin-left: 5px;
    min-width: 65em;
}

.app-button {
    height: 70px;
    width: 70px;
    border-radius: 5px;
    margin: 5px;
}

.app-procedures {
    background-color: #3770f8;
    align-self: end;
    margin-top: auto;
}

.app-procedures > .fa-info {
    width: 3em;
    height: 3em;
    color: white;
}

.img-app-small {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    border: 1px solid #c9c9c9;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
    transition: box-shadow 0.25s ease 0s;
    flex-shrink: 0;
}

.img-app-small:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 12px 24px -6px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

.img-app-small > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.update-available-toast {
    background-color: #1e2337;
    --toastify-color-light: #1e2337;
    color: white;
    --toastify-text-color-light: white;
    border: 1px solid white;
    cursor: default;
}

.update-available-toast > .Toastify__toast-body {
    margin-left: 0;
}

.btn-caia {
    color: white;
    border: 1px solid white;
}

.btn-caia:hover {
    color: white;
    background-color: #fa8400;
    border-color: #fa8400;
}

.video-scanner > video {
    max-width: 100%;
}

.image-scanner > video {
    display: none;
}

.barcode-result {
    bottom: 15px;
    right: 10%;
    font-size: 1.3em;
}

.barcode-file-input > .fa-image {
    margin-top: 12px;
}

.image-scanner {
    display: flex;
    max-height: 480px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
}

.image-scanner > img {
    left: calc(50% - 25px);
    top: calc(50% - 25px);
}

.video-scanner {
    text-align: center;
}

.video-scanner > .drawingBuffer {
    max-width: 100%;
}

.image-scanner > .drawingBuffer {
    height: 100%;
    width: 100%;
}

.max-height-modal > .modal-content {
    max-height: calc(100vh - 3.5rem);
    overflow: hidden;
}

.max-height-modal .modal-body {
    overflow: auto;
}

.qrcodes-admin {
    flex-grow: 1;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 5px;
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.qrcard {
    border: 1px solid black;
    padding: 16px;
    margin: 8px;
    border-radius: 5px;
    transition: filter 0.4s;
}

.qrcard.disabled {
    filter: opacity(0.5);
}

.qr-select {
    width: 16em;
}

.list-none {
    list-style-type: none;
}

.color-green {
    color: green;
}

.color-green:hover {
    color: green;
}

.user-picture-header {
    display: inline-block;
    margin-right: 5px;
    border: 2px solid #e67d09;
    border-radius: 8px;
}

.user-picture-header > img {
    height: 40px;
    width: 40px;
    border-radius: 6px;
}

.username-header {
    color: white;
}

@media print {
    .no-print {
        display: none !important;
    }

    .pe-no-print {
        display: none !important;
    }

    .pe-preserve-ancestor {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
        transform: none !important;
        top: 0;
    }
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.overflow-visible {
    overflow: visible;
}

.error-message {
    color: #bf1650;
}

.error-message::before {
    display: inline;
    content: "⚠ ";
}

.form-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.tall-modal-body {
    height: 80vh;
}

.breadcrumb {
    padding: 0.75rem 1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.text-left {
    text-align: left;
}