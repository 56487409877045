.gui-selector-oe-results.oes-slider {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: -webkit-fill-available;
    width: stretch;
    max-width: 50%;
}

.gui-selector-oe-results._3D {
    position: fixed;
    max-width: none;
}

.gui-selector-fiche-article {
    position: absolute;
    top: 40%;
    left: 0;
    bottom: 0;
    width: 50%;
}

.gui-selector-fiche-article > .fa-times-circle {
    position: absolute;
    right: 15px;
    background-color: white;
    padding: 3px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-bottom-left-radius: 5px;
}

.gui-selector-ean > .search-item-container {
    width: 100%;
    height: 100%;
}

.gui-selector-pdf > iframe {
    width: 100%;
    height: 100%;
}