.add-caia-item-button {
    border-radius: 50%;
    line-height: 1;
    padding: 4px 5px;
}

.add-caia-item-form > .footer {
    display: flex;
    margin-top: 10px;
}

.add-caia-item-form > .footer > :last-child {
    margin-left: auto;
}

.availability .fa-paper-plane {
    font-size: 28px;
    margin-top: 5px;
    margin-left: -3px;
    color: white;
}

.availability.unknown .fa-paper-plane > path {
    stroke: black;
    stroke-width: 15;
}