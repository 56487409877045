.recycling .engine-type {
    color: white;
    border-color: #6bd66b;
    background-color: #6bd66b;
}

.recycling .engine-code {
    color: black;
    border-color: yellow;
    background-color: yellow;
}
