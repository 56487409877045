.genart-selector-deal {
    flex-grow: 1;
}

.deal-left-panel {
    overflow-y: auto;
}

.deal-360 {
    flex-grow: 1;
}

.deal-360 > .photo360 {
    flex-grow: 1;
}