.toggle-component {
    position: relative;
    box-sizing: border-box;
    display: inline-grid;
    align-items: center;
    width: 48px;
    height: 24px;
    vertical-align: text-top;
    margin: 0 4px;
}

.toggle-component input[type="checkbox"]:checked + span {
    background-color: #3887b7;
}

.toggle-component input[type="checkbox"] + span {
    display: inline-grid;
    box-sizing: border-box;
    align-items: center;
    outline: none;
    user-select: none;
    width: 48px;
    height: 24px;
    background-color: #aaa;
    border-radius: 256px;
    transition: background ease-out 0.2s;
}

.toggle-component input[type="checkbox"]:checked + span::before {
    background-color: #fff;
}

.toggle-component input[type="checkbox"] + span::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 256px;
    width: calc( 48px - 2 * 2px );
    height: calc( 24px - 2 * 2px );
    background-color: #fff;
    left: 2px;
}

.toggle-component input[type="checkbox"]:checked + span::after {
    margin-left: calc( 100% - 16px - 4px );
    transition: all ease-out 0.2s;
    background-color: #3887b7;
}

.toggle-component input[type="checkbox"] + span::after {
    display: block;
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #aaa;
    transition: all ease-out 0.2s;
    margin-left: 4px;
}

