.search-item-container {
    width: 50em;
    padding: 3em;
}

.search-item-container .oes-table {
    max-height: calc(100vh - 330px);
}

.search-item-container > h2 > .rsbc-switch-button > label:first-child {
    font-size: 15px;
    margin-left: 15px;
}

.breadcrumb-filter::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-filter > input {
    background: transparent;
    border: none;
    border-bottom: solid;
    border-bottom-color: #e67d09;
    font-family: sans-serif;
    border-radius: 20px;
}

.breadcrumb-filter > input:focus {
    outline: none;
}

.search-item-container .Select {
    z-index: 2;
}