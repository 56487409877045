.vehicule-banner {
    background:linear-gradient(to left, #f58100 , #1e2337, #1e2337, #1e2337, #f58100);
    border-radius: 10px 10px 0 0;
    height: 50px;
    margin: 5px;
    color: white;
    font-weight: bold;
    padding: 5px;
    position: relative;
}

.vehicule-attributes {
    font-size: 0.8em;
    height: 1em;
}

.vehicule-banner > .star {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.vehicule-banner > .toggle-fullscreen {
    position: absolute;
    top: 15px;
    right: 55px;
}

.vehicule-banner .fa-times-circle {
    margin-left: 10px;
    color: red;
}