.title-with-icon {
    display: inline-block;
    color: white;
    vertical-align: text-top;
    margin-left: 5px;
}

.title-with-icon > span {
    margin-left: 5px;
}
