.select-parts {
    display: flex;
}

.part-list {
    height: calc(100vh - 350px);
    overflow-y: auto;
    width: 100%;
}

.families .part-list {
    height: inherit;
}

.attributes {
    white-space: nowrap;
    align-self: flex-start;
    border: 2px solid #495057;
    border-radius: 5px;
    padding: 5px;
}

.attributes > ul {
    padding-left: 5px;
}

.attributes ul {
    list-style-type: none;
}
