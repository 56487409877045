.wizard-navigation {
    position: relative;
    margin-bottom: 20px;
}

.wizard-navigation > .moving-tab {
    position: absolute;
    text-align: center;
    padding: 12px;
    background-color: #f44336;
    top: -4px;
    left: 0px;
    border-radius: 4px;
    color: white;
    box-shadow: 0 16px 26px -10px rgba(244, 67, 54, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
    transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.wizard-navigation > .nav-pills {
    background-color: rgba(200, 200, 200, 0.2);
    cursor: pointer;
}

.wizard-navigation > .nav-pills > li > a.active {
    background-color: inherit;
}

.wizard-navigation .nav-item {
    text-align: center;
    flex-grow: 1;
}

.stock-techauto .wizard-navigation .nav-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stock-techauto .wizard-navigation > .moving-tab {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}