.photo360 > img {
    width: 100%;
    position: static;
    margin-bottom: 5px;
    min-width: 400px;
    border-radius: 20px;
}

body.dragging {
    cursor: move;
}

.photo360 {
    position: relative;
}

.photo360 > .color-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-image: linear-gradient(to right, var(--vehicleColor) 0% 15%, rgba(0,0,0,0) 40% 60%, var(--vehicleColor) 85% 100%)
}

.photo360 > .critair-image-360 {
    width: 50px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    min-width: 50px;
    border-radius: 0;
}