.get-customer-data {
    position: absolute;
    right: 0;
    top: calc(20% - 40px);
    z-index: 1;
}

.get-customer-data > button {
    display: inline-block;
    position: absolute;
    left: -77px;
    top: 84px;
    padding: 5px;
    padding-right: 10px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    background-color: #ffffff;
    border: 1px #1e2335 solid;
    border-right: 1px #fff solid;
    width: 77px;
}

.gcd-content {
    position: relative;
    justify-content: center;
    align-items: center;
    background: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 6px 0 0 6px;
    width: 30em;
    height: 20em;
    overflow-y: auto;
    border: 1px #1e2335 solid;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}
