.shortcut-menu-button {
    position: absolute;
    color: black;
    background-color: #f5f8fa;
    bottom: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 28px;
    cursor: pointer;
    z-index: 1;
    border: 1px solid black;
}

.shortcut-menu {
    position: absolute;
    bottom: 40px;
    left: 30px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    background-color: #f5f8fa;
}

.shortcut-menu .marque > img, .shortcut-menu .marque > svg {
    width: 50px;
    height: 50px;
    display: block;
    object-fit: contain;
}