@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.table {
  display: table;
  width: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}
/*
#results {
  width: 100%;
  padding: 0 20px;
  max-width: 500px;
  margin: 20% auto;
  margin-top: 50px;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5em;
  position: absolute;
    z-index: 999999999;
    color: white;
}*/
/*
#results {
    width: 100%;
    padding: 0px 20px;
    max-width: 90%;
    margin-top: 5px;
    margin-left: 150px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5em;
    position: fixed;
    z-index: 999999999;
    color: white;
}*/
#results {
    width: 90vw;
    padding: 0px 20px;
    max-width: calc(100vw - 500px);
    margin-top: 5px;
    margin-left: 150px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5em;
    position: fixed;
    z-index: 999999999;
    color: white;
    text-align: right;
}

#interim_span {
  opacity: 0.4;
}

#tip {
  position: absolute;
  bottom: 100px;
  left: 6%;
  width: 250px;
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.75);
  margin-left: -125px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
#tip.show {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  left: 6%;
}

.visual {
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 400px;
  margin-bottom: -155px;
  border-radius: 100%;
  bottom: 50%;
  left: 50%;
  margin-left: -200px;
  z-index: 0;
  -webkit-transform: scale(0.001);
          transform: scale(0.001);
  mix-blend-mode: screen;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: rgba(255, 255, 255, 0.1);
}

 /*
#viz1 {
  background-color: #ff0000;
}
#viz2 {
  background-color: #00ff00;
}
#viz3 {
  background-color: #0000ff;
}*/
#visualization {
  position: absolute;
}
#visualization div {
  background-color: orange;
  padding: 10px;
  margin: 3px;
  width: 100px;
  display: inline-block;
}

#button {
  background-color: #595959;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  bottom: 20px;
  left: 6%;
  margin-left: -25px;
  position: absolute;
  z-index: 5;
}
#button:active {
  background-color: #dd0022;
}
#button #microphone {
  fill: #fff;
  width: 30px;
  position: absolute;
  left: 50%;
  margin-left: -14px;
  top: 20%;
}
#button.cancel #microphone {
  fill: transparent;
}
#button.cancel:before, #button.cancel:after {
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 16px;
  display: block;
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 50%;
  top: 50%;
  margin-left: -1px;
  margin-top: -8px;
  opacity: 0.8;
  z-index: 15;
}
#button.cancel:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
#button .element {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 3;
}
#button .element .slice {
  background-color: #595959;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 24px;
  height: 24px;
  border-radius: 24px 0 0 0;
  z-index: 10;
}
#button .loading {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border: 2px solid white;
  border-color: white transparent transparent transparent;
}
#button .loading.ring {
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.4);
  z-index: 4;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
