.mobilit {
    padding-top: 20px;
}

.select-brand.selection-brand {
    overflow-y: unset;
    display: flex;
    flex-direction: column;
}

.select-brand > .tabs-brands {
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
}

.tabs-brands .classic-cars {
    font-family: cursive;
    text-align: center;
    font-size: xx-large;
}