/*
	Sets the style of the image slider wrapper <div> to be centered horizontally and vertically.
	It also sets its default dimensions and the overflowing hidden (to fix the IE image positioning bug).
*/
#threesixty {
	position:absolute;
	/* This one fixes the IE positioning bug */
	overflow:hidden;/*
	top:50%;
	left:50%;
	width:960px;
	height:540px;
	margin-left:-480px;
	margin-top:-270px;*/
	width: 100%;
	height: 100%;
}
/*
	Makes the image slider invisible by default
*/
#threesixty_images {
	display: none;
}
/*
	Sets every image in the slider to be absolute positioned and dynamically sized.
*/
#threesixty img {
	position:absolute;
	/* This one fixes the IE positioning bug */
	top:0;
	width:100%;
	height:auto;
}
/*
	Sets the style of the visible current frame of the image slider animation
*/
.v2 .current-image {
	visibility:visible;
	width:100%;
}
/*
	Sets the style of the visible "previous" frame of the image slider animation
*/
.v2 .previous-image {
	visibility:hidden;
	width:0;
}
/*
	Sets the dimensions of the CanvasLoader placeholder <div>, it also centeres it horizontally and vertically inside the wrapper and makes it invisible by default
*/
#spinner {
	position:absolute;
	left:50%;
	top:50%;
	width:90px;
	height:90px;
	margin-left:-45px;
	margin-top:-50px;
	display:none;
}
/*
	Sets the style of the percentage <span> of the preloader by setting its dimensions and centering it horizontally and vertically
*/
#spinner span {
	position:absolute;
	top:50%;
	width:100%;
	color:#333;
	font:0.8em Arial, Verdana, sans;
	text-align:center;
	line-height:0.6em;
	margin-top:-0.3em;
}

