.change-password {
    flex-grow: 1;
    color: white;
    margin-top: 80px;
    margin-left: 25px;
}

.change-password label {
    display: block;
}

.change-password .link-to-my-account {
    margin-top: 50px;
}

.change-password .link-to-my-account > a {
    color: white;
}

.change-password .error-message {
    color: #bf1650;
}

.change-password .error-message::before {
    display: inline;
    content: "⚠ ";
}

.change-password input {
    margin-left: 8px;
}