.part-families {
    list-style-type: none;
    padding-left: 5px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.part-families > li, .part-families > li > a {
    width: 210px;
    height: 140px;
    display: block;
}

.part-families > li {
    margin: 10px;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
    background-position: top center;
    background-repeat: no-repeat;
    font-size: 0.8em;
    font-family: Arial;
    font-weight: bold;
}

.part-families > li > a > div {
    padding-top: 22px;
}

.part-families > li:hover {
    border-radius: 0;
}

.part-families > li > a:hover {
    color: white;
    text-decoration: none;
    background-image:url("../img/abfon.png");
    background-position: bottom left;
    background-repeat: repeat-x;
    transition: 0.3s;
}

.part-family > img {
    height: 80px;
}

.select-sub-family {
    overflow-y: auto;
    padding-right: 16px;
}

.selected-sub-family {
    font-weight: bold;
    text-decoration: underline;
}
