.login {
    flex-grow: 1;
    margin-top: 80px;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login > h3 {
    color: white;
    text-align: center;
}

.login > form {
    width: 30em;
}

.login input {
    margin-bottom: 20px;
}
