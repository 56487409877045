.diag.diag {
    width: 30em;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0px;
    overflow: auto;
}

.diag .marque > img {
    width: 70px;
    height: 70px;
}

.diag .square-app {
    display: inline-block;
    margin: 5px;
}

.diag .square-app > svg {
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 5px;
    border: 1px solid #1e2337;
    padding: 3px;
}

.adas-360 {
    flex-grow: 1;
}

.adas-360 > .vehicule-banner {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-weight: 700;
    position: absolute;
    z-index: 1;
    top: 26px;
    left: 8px;
    right: 8px;
    background: linear-gradient(270deg,#f58100 ,#1e23370a,#1e233705,#1e233700,#f58100);
    border-radius: 10px 10px 0 0;
}

.adas-triangle {
    width: 126px;
    height: 80px;
    background: linear-gradient(0deg,#f58100 ,#1e233700,#1e233700,#1e233700,#f58100);
    border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
    margin-top: -10px;
    display: flex;
}

.adas-triangle > div {
    margin-top: 27px;
    margin-left: auto;
    margin-right: auto;;
}

.diag-top {
    display: flex;
    width: 30em;
}

.diag-top > .btn {
    margin-left: 5px;
    margin-bottom: 10px;
}

.diag-top > .car-selector-search {
    width: auto;
    flex-grow: 1;
}

.adas-slide {
    background-color: black;
}

.adas-image > img {
    width: 100%;
}