.part-selector-oe.part-selector-oe {
    width: 30em;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
}

.part-selector-oe-top {
    display: flex;
    width: 30em;
    margin-bottom: 10px;
}
