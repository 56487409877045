.company-logo {
    border: 3px solid #e67d09;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
    display: inline-block;
    position: absolute;
    top: -20px;
    right: 20px;
    background-color: #fff;
}

.company-logo > img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.company-header {
    margin-top: 20px;
    border-radius: 8px;
    background: linear-gradient(90deg, #fff, #a7a5a6);
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    position: sticky;
    top: 20px;
    padding-left: 16px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
}

.app-content {
    display: flex;
    overflow: hidden;
    box-shadow: inset 2px 2px 20px 0 #656565;
    border-radius: 10px;
    padding: 8px;
}

.app-button.tooltip-arrow:after {
    content: "";
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: darkgray transparent;
    position: absolute;
    bottom: -22px;
    left: 25px;
    pointer-events: none;
}

.app-button-img.tire-app {
    width: 70px;
    height: 70px;
}

.rtpe-apps {
    overflow-x: auto;
    height: 96px;
}

.mobile-quote {
    height: 70vh;
}