.part-settings > .content {
    height: 400px;
}

.part-settings > .footer {
    display: flex;
    margin-top: 10px;
}

.part-settings > .footer > :last-child {
    margin-left: auto;
}
