.fiche-article {
    background: #ffffff;
    font: 10pt Verdana;
    color: #000;
    height: 100%;
    overflow: auto;
    flex-grow: 1;
}

.fiche-article h1 {
    font-size: 2em;
    font-weight: bold;
}

.fiche-article h2 {
    font-size: 1.5em;
    font-weight: bold;
}

.fiche-article h3 {
    font-size: 1.17em;
    font-weight: bold;
}

.fiche-article a{
  color: #1e2335;
}

.fiche-article a:hover{
  color: #2D91A8;
}

.fiche-article .breadCrumbs{
  text-align: left;
  padding: 5px 0 5px 10px;
  letter-spacing: 1px;
  line-height: 2em;
  color: #fff;
}

.fiche-article .breadCrumbs>a{
  text-decoration: none;
  color: #fff;
}

.fiche-article .breadCrumbs>a:hover{
  text-decoration: underline;
}

.fiche-article .clearFix:after
{
content: " ";
display: block;
height: 0;
clear: both;
}

.fiche-article .overview h1, .fiche-article .overview h2, .fiche-article .overview h3
{
  padding: 0;
  margin: 0 0 10px 0;
}
.fiche-article .overview>h2
{
  color: #cccccc;
}

.fiche-article .productImage
{
  width: 350px;
  text-align: center;
  margin-bottom: 20px;
  flex-grow: 1;
}

.fiche-article .button
{
display: block;
color: #fff;
border-radius: 4px;
padding: 5px;
margin-bottom: 10px;
text-decoration: none;
border-radius:3px;
text-align: center;
cursor: pointer;
}

.fiche-article .button a:hover
{
text-decoration: none;
}

.fiche-article .add{
  width: 200px;
  line-height: 2em;
  background: #99ca3c;
  font-size: 1.5em;
}

.fiche-article .add:hover{
  background: #4B8E0D;
}

.fiche-article .wish{
  width: 150px;
  background: #eb800a;
}

.fiche-article .wish:hover{
  background: #ad6127;
}
.fiche-article .compar{
  width: 150px;
  color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.fiche-article .compar:hover{
  color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.fiche-article .imageList {
    list-style: none;
    margin: 5px 25px 2px 25px;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.fiche-article .imageList a { text-decoration: none; }
.fiche-article .imageList img { border: 1px solid #D3D3D3; vertical-align: top; }

.fiche-article .prodSelect{
  border-radius: 4px;
  height: 2em;
  outline: none;
}

.fiche-article .cart-buttons > div:first-child {
  display: inline-block;
}

.fiche-article .rating{
  color: #FC913A;
}

.fiche-article .info{
  width: 100%;
  margin-bottom: 10px;
}

.fiche-article .info h3{
  background: #1e2335;
  line-height: 36px;
  padding: 5px 0 5px 20px;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #fff;
  text-shadow:1px 1px 3px rgba(0,0,0,0.5);
  font: 10pt Verdana;
  text-transform: uppercase;
}

.fiche-article .description {
  margin: 10px 0 20px 0;
  position: relative;
  padding-left: 40px;
}

.fiche-article .edit-description, .fiche-article .edit-buttons {
  position: absolute;
  top: -10px;
  left: 0;
  width: 40px;
}

.fiche-article .edit-buttons > .btn {
  padding-bottom: 0;
}

.fiche-article .description > textarea {
  width: 100%;
}

.fiche-article .product
{
width: 150px;
padding: 10px 0 0 10px;
display: inline-block;
text-align: center;
font-size: 11px;
line-height: 14px;
text-decoration: none;
margin-bottom: 20px;
overflow: hidden;
}

.fiche-article .product a{
  text-decoration: none;
}

.fiche-article .product a:hover{
  text-decoration: underline;
}

.fiche-article .product .smallBox
{
  display: inline-block;
  max-width: 92px;
  max-height: 92px;
  overflow: hidden;
}

.fiche-article .smallBox > img {
  width: 92px;
}

.fiche-article .product span, .fiche-article .product div
{
display: block;
}

.fiche-article .soft
{
padding-left: 8px;
}

.fiche-article .vtop
{
vertical-align: top;
}

.fiche-article .manuName
{
margin: 10px 0 5px 0;
font-weight: bold;
color: #464646;
}

.fiche-article .prodName
{
color: #464646;
margin: 0 0 5px 0;
}
.fiche-article .prodNameOE {
    margin: 0 0 5px 0;
    color: #5a9dff;
}
.fiche-article .prodNameIAM
{
color: #f6850f;
margin: 0 0 5px 0;
}
.fiche-article .prodNameEAN
{
margin: 0 0 5px 0;
color: #1a8414;
}
.fiche-article .prodPrice
{
text-decoration: none;
}

.fiche-article .botBorder{
  padding: 10px;
  border: 1px solid #B6B6AB;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 20px;
}

.fiche-article .folderTab {
background: #1e2335;
text-shadow: 1px 1px 0 rgba(0,0,0, .8);
text-align: center;
color: #fff;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
border: 1px solid #1e2335;
}

.fiche-article .availability {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  text-align: center;
}

.fiche-article .item-price > h3 {
  display: inline-block;
}

.fiche-article .attribute-mandatory {
  font-weight: bold;
}

.fiche-article .missing-attribute {
  font-style: italic;
}

.tecdoc-price {
  margin-bottom: 1em;
  font-weight: 500;
  font-size: 1.17em;
}

.tecdoc-price > .price-date {
  margin-right: 1em;
}

.tecdoc-price > .formatted-price {
  margin-right: 1em;
  font-weight: bold;
}

.tecdoc-price > .quantity-unit {
  margin-left: 1em;
}

.tecdoc-price > .flag-select__option__icon {
  margin-right: 0.5em;
}

.fiche-article .overview {
  margin-left: 1em;
  min-width: 25em;
  width: min(50%, 40em);
}

.fiche-article .article-details {
  display: flex;
  flex-wrap: wrap;
}

.fiche-article .attributes-list > li {
    font-size: 15px;
}