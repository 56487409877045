.notification-sender > form {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    min-width: 400px;
}

.notif-img-preview {
    object-fit: scale-down;
}

.notif-users-count {
    margin-left: 10%;
    margin-bottom: 1rem;
}

.public-checkbox {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    align-self: center;
    cursor: pointer;
}
