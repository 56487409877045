.vehicle-applicability-list {
    list-style-type: none;
    max-height: 50vh;
    overflow-y: auto;
}

.vehicle-applicability-list li:nth-child(even) {
    background-color: #e2e2e2;
}

.vehicle-applicability .marque > img {
    width: 80px;
}

.bg-caia {
    background-color: orange;
    color: white;
}

.vehicle-applicability .marque {
    position: relative;
}

.vehicle-applicability .bg-caia {
    position: absolute;
    bottom: -10px;
    left: 5px;
}