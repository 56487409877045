.iframe-viewer > .env-logo {
    position: fixed;
    left: 16px;
    bottom: 16px;
    width: 110px;
    height: 110px;
    box-shadow: 4px 13px 30px 1px rgb(229, 123, 8);
    border-radius: 20px;
}

.iframe-viewer > .env-logo > a > img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.iframe-viewer > .user-profile-bloc {
    position: fixed !important;
    top: 1rem;
    right: 1rem;
}
