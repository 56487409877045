body {
    margin: 0;
    padding: 0;
    background-color: #f5f8fa;
}

::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 8px;
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #1e233717;
    border-radius: 8px;
}
::-webkit-scrollbar-button,
::-webkit-resizer {
    background: transparent;
    border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

html,
body,
#root {
    height: 100%;
}
