.vendor-highlight {
  background-image: linear-gradient(135deg,
      hsl(0deg 0% 42%) 0%,
      hsl(0deg 0% 71%) 50%,
      hsl(0deg 0% 100%) 100%);
}

.customer-highlight {
  background-image: linear-gradient(135deg,
      hsl(0deg 0% 100%) 0%,
      hsl(140deg 92% 84%) 72%,
      hsl(150deg 90% 58%) 100%);
}

.customer-highlight.vendor-highlight {
  background-image: linear-gradient(135deg,
      hsl(0deg 0% 42%) 0%,
      hsl(0deg 0% 71%) 50%,
      hsl(140deg 92% 84%) 72%,
      hsl(150deg 90% 58%) 100%);
}