.adncatalog-tab {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.adncatalog-table {
    min-height: 200px;
}

.adncatalog-tab .rt-tr:hover {
    background-color: #eee;
}

.adncatalog-tab .app-oem-details {
    height: 250px;
}

.adncatalog-tab .apps {
    margin-bottom: 20px;
}

.adncatalog-tab .app-oem-block .tooltip-arrow::after {
    bottom: -25px;
}