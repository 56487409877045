.stock-occasion {
    margin-top: 50px;
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.search-form {
    border: 1px solid gray;
    border-radius: 8px;
    padding: 20px;
}

.vehicle-list {
    margin-top: 20px;
    overflow: auto;
}