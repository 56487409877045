.vehicle-data-wrapper {
    overflow-y: auto;
    flex-grow: 1;
    box-shadow: inset 2px 2px 20px 0 #656565;
    border-radius: 20px;
    padding-top: 8px;
    padding-left: 16px;
    padding-bottom: 8px;
    margin-top: 20px;
}

.vehicle-data-header {
    margin-top: 5px;
}

.vehicle-data-header > div > img {
    border-radius: 20px;
}
.vehicle-data-header > .color-circle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    box-shadow: 0 0 50px rgba(0, 0, 0, .25);
}

.vehicle-data-header > .siren-indicator {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

.vehicle-data-header > .fa-accusoft {
    width: 2em;
    height: 2em;
    vertical-align: middle;
}

.vehicle-data-rows > tbody > tr {
    border-top: 1px dotted grey;
    border-bottom: 1px dotted grey;
}

.vehicle-data-rows > tbody > tr > td {
    border-left: 1px dotted grey;
    border-right: 1px dotted grey;
}

.vehicle-data-rows > tbody > tr:hover {
    background-color: #99ca3c14;
}

.vehicle-data-euro-norm {
    font-weight: bold;
}

.vehicle-data-co2-emissions > span {
    color: white;
    display: inline-block;
    position: relative;
    width: 30px;
    text-align: center;
    margin-left: 3px;
    font-weight: bold;
}
.vehicle-data-co2-emissions > span:before {
    content: "";
    position: absolute;
    right: -24px;
    bottom: 0;
    width: 0;
    height: 0;
    border: 12px solid transparent;
}

.vehicle-data-co2-emissions.co2-emissions-A > span { background-color: #10884A }
.co2-emissions-A > span:before { border-left-color: #10884A; }
.vehicle-data-co2-emissions.co2-emissions-B > span { background-color: #2DB549 }
.co2-emissions-B > span:before { border-left-color: #2DB549; }
.vehicle-data-co2-emissions.co2-emissions-C > span { background-color: #8BC741 }
.co2-emissions-C > span:before { border-left-color: #8BC741; }
.vehicle-data-co2-emissions.co2-emissions-D > span { background-color: #EAE846 }
.co2-emissions-D > span:before { border-left-color: #EAE846; }
.vehicle-data-co2-emissions.co2-emissions-E > span { background-color: #E1AC2C }
.co2-emissions-E > span:before { border-left-color: #E1AC2C; }
.vehicle-data-co2-emissions.co2-emissions-F > span { background-color: #D75E27 }
.co2-emissions-F > span:before { border-left-color: #D75E27; }

.vehicle-test {
    font-weight: bold;
}
.critair {
    display: flex;
    align-items: center;
}
.critair-image {
    width: 40px;
    height: 40px;
}
