.genart-list {
    display: flex;
    flex-grow: 1;
}

.genart-list-select {
    border: 1px solid #1e2337;
    border-right-style: none;
    border-radius: 5px 0 0 5px;
    background-color: white;
    align-self: start;
    overflow: hidden;
    max-width: 55px;
    transition: max-width 800ms;
    white-space: nowrap;
    padding: 5px;
    padding-right: 0;
}

.genart-list-select > ul {
    list-style-type: none;
    padding: 5px;
    margin-bottom: 0;
}

.genart-list-select:hover {
    width: auto;
    overflow: visible;
    border-radius: 5px;
    margin-right: 5px;
    max-width: 600px;
    padding-right: 5px;
}

.genart-list > .item-bloc {
    flex-grow: 1;
    width: inherit;
    position: relative;
    overflow: visible;
}

.genart-list .selected {
    font-weight: bold;
    text-decoration: underline;
}

.genart-list .genart-checkbox {
    margin-right: 5px;
    cursor: pointer;
}

.genart-list .deal-cart-button {
    margin-left: 5px;
}

.genart-list .deal-cart-button > span {
    margin-left: 10px;
}

.genart-list .custom-search-breadcrumb-image {
    width: 55px;
    height: 55px;
    margin: 8px;
}
