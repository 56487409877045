.dashboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #eeeef5;
}

.dashboard-row {
    display: flex;
}

.dashboard .card {
    flex-grow: 2;
    margin: 8px;
    padding: 8px;
}

.dashboard-header {
    color: white;
    background-color: #1e2337;
    display: flex;
    align-items: center;
}

.dashboard-footer {
    padding: 20px;
    text-align: center;
}

.dashboard .model-badge {
    position: absolute;
    bottom: revert;
    top: 65px;
    left: 18px;
}

.dashboard .model-name {
    margin-top: 10px;
}