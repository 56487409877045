.dtc-search.details {
    overflow-y: unset;
    flex-direction: column;
}

.dtc-search-form {
    margin-right: 220px;
}

.dtc-submit-btn {
    position: absolute;
    right: 220px;
    bottom: 10px;
}

.dtc-code-label {
    font-weight: bold;
}