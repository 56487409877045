.connections-apps > form > div {
    flex-grow: 1;
}

.connections-apps {
    margin-bottom: 20px;
}

.main-bloc.custom-search-bloc {
    width: 48em;
}

.user-profile-picture {
    border: 3px solid #e67d09;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -30px;
}

.user-profile-bloc > .user-profile-picture {
    top: -10px;
}

.user-profile-picture > img {
    border-radius: 8px;
    width: 80px;
    height: 80px;
}

.user-name-bloc {
    background: linear-gradient(90deg, #fff, #a7a5a6);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
    height: 50px;
    flex-grow: 1;
    border-radius: 0 8px 8px 0;
    margin-left: 75px;
}

.user-name-bloc > .user-company {
    margin-left: auto;
    margin-right: 50px;
}
