.part-selector-search {
    display: flex;
}

.part-selector-search > .toggle-exact-search {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: 5px;
    margin-top: 8px;
    color: white;
}

.part-selector-search > .car-selector-search {
    width: 25em;
}

.part-selector-search > form > div[role=combobox] {
    flex-grow: 1;
}

.part-selector-search > form > div[role=combobox]::after {
    content: '';
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: white transparent;
    margin: auto;
}
