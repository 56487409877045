@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
.v2 .triangle {
    display: block;
    width: 126px;
    height: 80px;
    background: linear-gradient(0deg,#f58100,#1e233700,#1e233700,#1e233700,#f58100);
    border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
    left: 50%;
    right: 50%;
    position: absolute;
    top: 0px;
}


* {
  box-sizing: border-box;
}

body.v2 {
  min-height: 100vh;
  overflow: hidden;
}

.v2 .blog-slider {
    width: 100%;
    position: absolute;
    max-width: 474.2px;
    max-height: 272.42px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    transition: all .3s;
    bottom: 4%;
    left: 5%;
    height: 27%;
    z-index: 1;
}
.v2 .blog-slider2 {
    width: 95%;
    position: absolute;
    max-width: 800px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
    transition: all .3s;
    bottom: 4%;
    left: 5%;
}
@media screen and (max-width: 992px) {
.v2 .blog-slider {
    max-width: 680px;
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
.v2 .blog-slider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
.v2 .blog-slider {
    height: 350px;
  }
}
.v2 .blog-slider__item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
.v2 .blog-slider__item {
    flex-direction: column;
  }
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: .3s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  transform: none;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
  transition-delay: 0.6s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
  transition-delay: 0.7s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
  transition-delay: 0.8s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
  transition-delay: 0.9s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
  transition-delay: 1s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
  transition-delay: 1.1s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
  transition-delay: 1.2s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
  transition-delay: 1.3s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
  transition-delay: 1.4s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
  transition-delay: 1.5s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
  transition-delay: 1.6s;
}
.v2 .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
  transition-delay: 1.7s;
}
.v2 .blog-slider__img {
    width: 110px;
    flex-shrink: 0;
    height: 110px;
    box-shadow: 4px 13px 30px 1px rgb(229, 123, 8);
    border-radius: 20px;
    transform: translateX(-80px);
    overflow: hidden;
    background-image: linear-gradient(147deg, #000000 0%, #1e2337 74%);
}
.v2 .blog-slider__img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);*/
  border-radius: 20px;
  opacity: 0.8;
}
.v2 .blog-slider__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 20px;
  transition: all .3s;
}
@media screen and (max-width: 768px) {
.v2 .blog-slider__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
.v2 .blog-slider__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
.v2 .blog-slider__img {
    height: 270px;
  }
}
.v2 .blog-slider__content {
  padding-right: 25px;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
.v2 .blog-slider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
.v2 .blog-slider__content {
    padding: 0;
  }
}
.v2 .blog-slider__content > * {
  opacity: 0;
  transform: translateY(25px);
  transition: all .4s;
}
.v2 .blog-slider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.v2 .blog-slider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.v2 .blog-slider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.v2 .blog-slider__button {
  display: inline-flex;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
.v2 .blog-slider__button {
    width: 100%;
  }
}
.v2 .blog-slider .swiper-container-horizontal > .swiper-pagination-bullets, .blog-slider .swiper-pagination-custom, .blog-slider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
.v2 .blog-slider__pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.v2 .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
.v2 .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.v2 .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all .3s;
}
.v2 .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fd3838;
  height: 30px;
  box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
}
@media screen and (max-width: 768px) {
.v2 .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}
