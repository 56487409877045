
.flag-select {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    text-align: left;
    margin-bottom: 1em;
}

.flag-select__btn {
    font-size: 16px;
    padding: 0 8px;
    color: white;
    border: none;
    background: transparent;
}

.flag-select__btn::after {
    content: " ";
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    border-top: 5px solid #4D4D4D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0;
}
.flag-select__btn[aria-expanded="true"]::after {
    border-top: 0;
    border-bottom: 5px solid #4D4D4D;
}

.flag-select__option {
    cursor: pointer;
    white-space: nowrap;
    margin: 4px 0;
}

.flag-select__option.has-label {
    padding: 0 20px 3px 8px;
}

.flag-select__option__icon {
    width: 1.3em;
    height: 1.3em;
    position: relative;
}

.flag-select__option__label {
    font-size: 1em;
    position: relative;
    padding-left: 9px;
}

.flag-select__options {
    position: absolute;
    z-index: 99999999;
    border: 1px solid #BDBBBB;
    border-radius: 3px;
    background-color: #1e2337;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 160px;
    overflow: auto;
}
