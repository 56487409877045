.oe-selector.oe-selector {
    width: 30em;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.oeselector-top {
    display: flex;
    width: 30em;
}

.oeselector-top > .btn {
    margin-bottom: 10px;
}