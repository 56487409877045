.shopping-cart {
    padding: 0 10px;
    margin-top: 25px;
}

.shopping-cart ul {
    list-style-type: none;
    padding-left: 0;
}

.inner-content > .shopping-cart {
    background-color: white;
    margin: 20px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cart-footer {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    color: green;
}

.cart-footer > a {
    color: white;
    background-color: #2185d0;
    padding: 8px;
    border-radius: 5px;
}

.cart-title {
    margin-top: 16px;
}
