.checkout-form {
    background-color: white;
    margin: 16px;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.checkout-form input[type=text] {
    width: 100%;
}

.checkout-form label {
    margin-right: 10px;
    margin-left: 10px;
}

.checkout-form select {
    margin-left: 10px;
}

.two-fields {
    display: flex;
}

.two-fields > label {
    width: 100%;
}

.checkout-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.order-complete {
    background-color: white;
    margin: 16px;
    padding: 16px;
    border-radius: 8px;
}
