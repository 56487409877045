.albums {
    list-style-type: none;
    padding-left: 5px;
    position: relative;
}

.albums > li {
    display: inline-block;
    margin: 5px;
    vertical-align: top;
}

.albums .details {
    display: inline-flex;
    background-color: white;
    position: absolute;
    border-radius: 10px;
    left: 10px;
    right: 10px;
    white-space: nowrap;
    box-shadow: inset 2px 2px 20px 0px #656565;
    overflow-y: auto;
    margin-top: 109px;
    justify-content: space-between;
    transition: all 500ms;
    height: 0;
    opacity: 0;
}

.albums.families .details {
    margin-top: 0;
}

.albums .details > ul {
    list-style-type: none;
    padding-left: 0;
    display: inline-block;
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 0;
}

.albums .details-list {
    list-style-type: none;
    padding-left: 0;
}

.albums li.selected .details {
    height: 250px;
    padding: 10px;
    opacity: 1;
    transition: height 500ms;
}
.albums.families .details {
    justify-content: flex-start;
}

li.selected {
    padding-bottom: 250px;
}
.big-li.selected {
    padding-bottom: 450px;
}

.families .tooltip-arrow {
    position: relative;
}

li.selected .tooltip-arrow:after {
    content: '';
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: darkgray transparent;
    margin: auto;
}

.families li.selected .tooltip-arrow:after {
    position: absolute;
    bottom: 0;
    left: 80px;
}

.marque {
    cursor: pointer;
}

.illustration {
    height: 175px;
    position: absolute;
    border-radius: 10px;
    right: 10px;
    bottom: 35px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.custom-search > .illustration {
    position: static;
    align-self: center;
    margin-left: 10px;
}

.model-name {
    width: 120px;
    margin-top: 5px;
}

.family-album {
    width: 210px;
    height: 140px;
    display: block;
}

.family-album > img {
    height: 80px;
}

.select-part {
    margin-left: 20px;
}

.custom-search > div {
    display: flex;
    align-items: flex-end;
}

.tire-input .illustration {
    width: 350px;
}

.albums li.selected .details.gui-selector, .albums li.selected .details.genart-selector {
    padding: 20px;
    height: 450px;
    white-space: normal;
}

.gui-selector > .d-flex > button, .genart-selector > .d-flex > button {
    position: absolute;
    left: -90px;
}

.details.gui-selector > ul, .details.genart-selector > div {
    flex-shrink: 1;
}

.details.gui-selector > div, .details.genart-selector > div {
    flex-shrink: 0;
    position: relative;
    margin-left: 5px;
}

.albums .marque {
    position: relative;
}

.albums .marque > .badge {
    position: absolute;
    top: 60px;
    left: 5px;
    background-color: #007BFF;
}

.badge-version {
    background-color: #007BFF;
    margin-right: 5px;
}