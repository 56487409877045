.expert-data-switch {
    display: inline-block;
    margin-right: 10px;
    margin-left: 100px;
}

.expert-data-switch label {
  width: 90px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-radius: 14px;
  background-clip: padding-box;
  background: rgb(138, 138, 138);
  background: linear-gradient(
    to bottom,
    rgba(138, 138, 138, 1) 0%,
    rgba(140, 140, 140, 1) 14%,
    rgba(159, 159, 159, 1) 72%,
    rgba(164, 164, 164, 1) 100%
  );
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  line-height: 30px;
  font-style: normal;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, color-stop(0.5, transparent), to(rgba(255, 255, 255, 0.3)));
  transition: all 1s ease-in;
  cursor: pointer;
}

.expert-data-switch label i {
  position: absolute;
  top: -1px;
  right: 60px;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 36px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 1) 11%,
    rgba(228, 228, 228, 1) 50%,
    rgba(221, 221, 221, 1) 53%,
    rgba(205, 205, 205, 1) 97%,
    rgba(191, 191, 191, 1) 100%
  );
  box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 1px 0 rgba(255, 255, 255, 0.4), 0 2px 0 0 rgba(0, 0, 0, 0.2);
  transition: all 200ms ease;
}

.expert-data-switch label i:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 18px;
  background: rgb(239, 239, 239);
  background: linear-gradient(
    to bottom,
    rgba(239, 239, 239, 1) 0%,
    rgba(225, 225, 225, 1) 6%,
    rgba(225, 225, 225, 1) 24%,
    rgba(229, 229, 229, 1) 94%,
    rgba(242, 242, 242, 1) 100%
  );
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.expert-data-switch label:hover i {
  box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 1px 0 rgba(255, 255, 255, 0.4), 0 2px 0 0 rgba(0, 0, 0, 0.3);
}
.expert-data-switch label:active i:before {
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.3);
}

.expert-data-switch label>span {
  margin-left: 30px;
  text-transform: uppercase;
  transition: all 200ms ease;
}

.expert-data-switch label>div {
  right: 85px;
  width: 100px;
  font-size: 14px;
  color: #999;
  position: absolute;
  top: 0;
}

.expert-data-switch input[type="checkbox"]:checked ~ label>span {
  text-transform: uppercase;
  margin-left: 30px;
}

.expert-data-switch input[type="checkbox"]:checked ~ label {
  background: rgb(141, 173, 51);
  background: radial-gradient(
    center,
    ellipse farthest-corner,
    rgba(141, 173, 51, 1) 0%,
    rgba(146, 178, 55, 1) 24%,
    rgba(157, 187, 64, 1) 55%,
    rgba(166, 194, 78, 1) 100%
  );
}

.expert-data-switch input[type="checkbox"]:checked ~ label i {
  right: -6px;
}

/* you can hide leter */
.expert-data-switch input:before {
  content: "Logic:";
  margin-left: -35px;
  font-size: 10px;
  color: #333;
}
.expert-data-switch input {
    display: none;
}
