.hubidlp-bg {
    position: absolute;
    top: 50px;
    left: 40px;
    right: 0;
    bottom: 0;
}

.hubidlp-bg > iframe {
    border: none;
}