:root {
    --slide-perspective: 1300px;
}

@media (min-height: 1020px) {
    :root {
        --slide-perspective: 2000px;
    }
}

@media (min-height: 1600px) {
    :root {
        --slide-perspective: 3000px;
    }
}

@media (min-height: 2100px) {
    :root {
        --slide-perspective: 4000px;
    }
}

.stack-slider {
    position: relative;
    height: 100%;
    z-index: 3;
}

.slide {
    position: absolute;
    top: 32px;
    left: 0;
    transition: all 0.5s ease;
    transform: perspective(var(--slide-perspective)) translate3d(0px, -130px, -500px);
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    overflow: hidden;
}

.slide.extended {
    position: fixed;
    top: 50px;
    height: calc(100vh - 50px);
}

.stack-slider > .slide.active {
    transform: perspective(var(--slide-perspective)) translate3d(0px, 100px, 0px);
    opacity: 0;
}

.slide:nth-last-child(4) {
    transform: perspective(var(--slide-perspective)) translate3d(0px, -110px, -290px);
}

.slide:nth-last-child(3) {
    transform: perspective(var(--slide-perspective)) translate3d(0px, -75px, -210px);
}

.slide:nth-last-child(2) {
    transform: perspective(var(--slide-perspective)) translate3d(0px, -40px, -130px);
}

.slide:nth-last-child(1) {
    transform: perspective(var(--slide-perspective)) translate3d(0px, -5px, -50px);
    overflow: auto;
}

.stack-slider .btn.btn-none {
    margin-top: -11px;
    padding: 6px 4px;
}

._3D .stack-slider {
    width: 85%;
}

._3D .slide {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(440px, -230px, -1950px);
    opacity: 0;
    height: 80%;
    max-width: 1600px;
}

._3D .slide:nth-last-child(1) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, 20px, 0px);
    opacity: 1;
}

._3D .slide:nth-last-child(2) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, -40px, -200px);
    opacity: 1;
}

._3D .slide:nth-last-child(3) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, -100px, -400px);
    opacity: 1;
}

._3D .slide:nth-last-child(4) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, -160px, -600px);
    opacity: 1;
}

._3D .slide:nth-last-child(5) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, -175px, -800px);
    opacity: 1;
}

._3D .slide:nth-last-child(6) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(510px, -190px, -1000px);
    opacity: 1;
}

._3D .slide:nth-last-child(7) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(490px, -200px, -1200px);
    opacity: 1;
}

._3D .slide:nth-last-child(8) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(480px, -210px, -1450px);
    opacity: 1;
}

._3D .slide:nth-last-child(9) {
    transform: perspective(var(--slide-perspective)) rotateY(30deg) translate3d(460px, -220px, -1700px);
    opacity: 0.5;
}

._3D .slide.active {
    opacity: 0;
    transform: perspective(var(--slide-perspective)) rotateX(0deg) rotateY(35deg) rotateZ(0deg) scale3d(1, 1, 1) translate3d(515px, 100px, 300px);
}

.titlebar-buttons {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 25px;
    background-color: white;
    text-align: center;
    cursor: pointer;
    border-bottom-left-radius: 5px;
    padding: 2px 5px;
}
.titlebar-buttons > svg {
    margin-right: 5px;
}

.slide-wrapper {
    overflow: auto;
    height: 100%;
}

.slide-title {
    height: 20px;
    margin-bottom: 0;
}

.slide-context {
    display: flex;
    height: calc(100% - 30px);
}

.stack-slider-buttons {
    font-size: 32px;
    line-height: 32px;
    margin-right: 110px;
}

.stack-slider-buttons > .fa-barcode:hover {
    color: #E67D09;
}
.stack-slider-buttons > .fa-window-restore {
    color: #E67D09;
}
.stack-slider-buttons > .fa-window-restore:hover {
    color: red;
}