.breadcrumb {
    background-color: #f581004f;
    background:linear-gradient(to right, #ffffff , #a7a5a6);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.breadcrumb > .breadcrumb-img .fa-search,
.breadcrumb > .breadcrumb-img > svg
{
    width: 80%;
    height: 80%;
    margin: auto;
}

.breadcrumbs-wrapper {
    position: absolute;
    left: 20px;
    right: 20px;
    z-index: 1;
    background-color: white;
}
.breadcrumbs-wrapper.breadcrumbs-family {
    margin-top: -20px;
    padding-top: 20px;
    height: 68px;
}

.breadcrumbs-wrapper.breadcrumbs-wrapper + * {
    margin-top: 100px;
}

.breadcrumb-img {
    position: absolute;
    top: -5px;
    right: 15px;
    border: 3px solid #e67d09;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(0,0,0,.25);
    width: 100px;
    height: 100px;
    display: flex;
    background-color: white;
}

.breadcrumb-img-brand, .breadcrumb-img-model {
    top: 8px;
    left: 5px;
    width: 70px;
    height: 70px;
    right: inherit;
}

.breadcrumb-img-model {
    left: 85px;
}

.breadcrumb-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.breadcrumb-img + .breadcrumb-item {
    margin-left: 80px;
}

.breadcrumb-img + .breadcrumb-img + .breadcrumb-item {
    margin-left: 150px;
}