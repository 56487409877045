.dock {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    display: flex;
    justify-content: space-around;
    width: 60%;
}

.dock-background {
    position: absolute;
    width: 70%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-bottom: 50px solid gray;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    opacity: 0.8;
}

.dock .app-logo-reflection {
    opacity: 0.3;
    transform: scale(1, -1);
    background-color: #f5f8fa;
    border-radius: 50%;
}

.dock .app {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #f5f8fa;
    text-align: center;
}

.dock .app-logo {
    margin-top: 4px;
}
