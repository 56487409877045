.app-albums {
    list-style-type: none;
    padding-left: 5px;
    position: relative;
    display: flex;
    flex: 1;
}

.app-albums-details {
    margin-top: 12px;
    background-color: white;
    position: absolute;
    border-radius: 10px;
    left: 10px;
    right: 10px;
    white-space: nowrap;
    box-shadow: inset 2px 2px 20px 0px #656565;
    overflow-y: auto;
    transition: all 500ms;
    height: 0;
    opacity: 0;
}

.app-albums .selected {
    padding-bottom: 320px;
}

.selected .app-albums-details {
    height: 300px;
    padding: 10px;
    opacity: 1;
}

.app-albums .tooltip-arrow {
    position: relative;
}

.app-albums .selected .tooltip-arrow:after {
    position: absolute;
    content: '';
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: darkgray transparent;
    margin: auto;
    top: 76px;
}