.product-alternative {
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
}

.product-alternative > .marque {
    position: relative;
    margin-bottom: 10px;
}

.product-alternative > .marque > .badge {
    position: absolute;
    bottom: -10px;
    left: 5px;
    background-color: #007BFF;
}

.product-alternative-list {
    list-style-type: none;
    padding-left: 15px;
    margin-top: 5px;
}

.product-alternative-list > li > a {
    color: #007BFF;
}