.part-selector .albums.families {
    margin-top: 60px;
}

.part-selector {
    width: 100%;
}

.part-selector.part-selector-home {
    width: inherit;
}

.part-selector > .search-item-container {
    width: 100%;
}

.part-selector > .selection-bloc {
    width: 100%;
}

.part-selector.part-selector-home > .selection-brand {
    width: 30em;
}

.partselector-cross {
    color: red;
    margin-left: 10px;
}

.breadcrumb-toggle {
    position: absolute;
    top: 0;
    right: 120px;
    cursor: pointer;
}

.other-brands > input {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 8px;
}
