.user-profile-bloc {
    white-space: nowrap;
}

.user-profile-bloc.reduced .user-name-bloc {
    min-width: 50px;
}

.user-profile-bloc.reduced .user-name-bloc > span {
    display: none;
}

.user-profile-bloc.reduced.upb-exit .user-name-bloc > span {
    display: block;
}

.user-profile-bloc.reduced .user-name-bloc > .dropdown {
    display: none;
}

.user-profile-bloc.reduced.upb-exit .user-name-bloc > .dropdown {
    display: block;
}

.user-profile-bloc.reduced .cart-link {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.user-profile-bloc.reduced.upb-exit .cart-link {
    display: initial;
    flex-direction: unset;
    margin-left: unset;
}

.user-profile-bloc.reduced .user-name-bloc,
.upb-enter .user-name-bloc {
    overflow: hidden;
}

.upb-enter {
    max-width: 140px;
}

.upb-enter-active {
    max-width: 500px;
    transition: max-width 0.5s;
}

.upb-exit {
    max-width: 500px;
}

.upb-exit-active {
    max-width: 140px;
    transition: max-width 0.5s;
}
