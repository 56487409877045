.astuces {
    position: absolute;
    right: 0;
    top: 20%;
    z-index: 2;
}

.astuces-button {
    display: inline-block;
    position: absolute;
    left: -77px;
    top: 114px;
    padding: 5px;
    padding-right: 10px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    background-color: #ffffff;
    border: 1px #1e2335 solid;
    border-right: 1px #fff solid;
    width: 77px;
}

.astuces-button > .badge {
    border-radius: 10px;
    position: absolute;
    top: 34px;
    right: 0;
}

.astuces-content {
    display: inline-block;
    background: white;
    margin-top: -20px;
    padding: 10px;
    border-radius: 6px 0 0 6px;
    width: 40em;
    max-height: 70vh;
    overflow-y: auto;
    border: 1px #1e2335 solid;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.astuces-content > h3 {
    color: #f78300;
}

.astuces-content > ul > li > span {
    color: #f78300;
    margin-left: 10px;
}

.astuces-content > ul > li > div {
    border-radius: 5px;
    margin: 5px 3px 12px;
    overflow: auto;
    border: 2px #bfbebe solid;
    height: 143px;
}

.astuces-content .btn-link {
    padding: 0;
    color: #1e2337;
}
