.oes-breadcrumbs {
    margin-top: 5px;
    margin-bottom: 5px;
}

.oes-breadcrumbs > .btn-link {
    padding: 0;
}

.oes-table {
    max-height: calc(100vh - 470px);
    overflow-y: auto;
    margin: 10px;
}
.oes-table.stretch {
    max-height: none;
    overflow-y: initial;
    flex-grow: 1;
}

.oes-table .btn {
    margin-left: 5px;
}

.strike {
    text-decoration: line-through;
    color: gray;
}

.oes-table > table > thead {
    background-image: linear-gradient(
        to left,
        rgb(178, 176, 177) 1%,
        rgb(240, 238, 239) 50%,
        #ffffff 100%
    );
    color: #1e2337;
}

.oe-name {
    color: #f68510;
    cursor: pointer;
}

.oe-ean {
    color: green;
    cursor: pointer;
}

.oe-ean + .btn {
    color: green;
}

.oes-table tbody > tr {
    border-bottom-color: gray;
    border-bottom-style: dotted;
    margin: 12px;
}

.oes-table tbody > tr:hover {
    background-color: #99ca3c14;
}

.oes-table .ReactTable {
    height: calc(100vh - 390px);
}
.oes-table.stretch .ReactTable {
    height: 100%;
}
.oes-table .ReactTable.ArticleTable {
    height: calc(100vh - 470px);
}
.oes-table.stretch .ReactTable.ArticleTable {
    height: revert;
}

.ArticleTable .btn-link {
    padding: 0;
    margin-right: 2px;
}

.rt-td > .btn-link {
    padding: 1px 6px;
}

.ReactTable .rt-tr > .rt-td {
    white-space: normal;
}

.icon-button {
    margin-left: 10px;
    cursor: pointer;
}

.oes-slider._3D {
    width: 100%;
    height: calc(100% - 50px);
    position: fixed;
    right: 0;
    top: 50px;
    background: rgba(30, 35, 55, 0.9);
    padding-top: 25px;
    z-index: 3;
}

.Select {
    z-index: 1;
}

.leaf-icon {
    color: #3a5f0b;
}

.oe-price,
.oe-availability,
.cart-button {
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 50px;
}
.oe-price:last-child,
.oe-availability:last-child,
.cart-button:last-child {
    border-bottom: none;
}
.oe-price {
    padding-top: 12px;
    text-align: right;
}
.cart-button {
    text-align: center;
    padding-top: 13px;
    border-radius: 0;
}

.oe-price:first-child,
.cart-button:first-child {
    margin-top: 27px;
}
.oe-price.local-price,
.cart-button.local-item {
    margin-top: 0;
}

.availability {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid black;
    margin: auto;
}

.availability.available {
    background-color: green;
}

.availability.unavailable {
    background-color: red;
}

.availability.availability-error {
    border: none;
    color: gray;
}

.compat-recommanded {
    color: #3cad59;
}

.compat-not-recommanded {
    color: #e1ac2c;
}

.app-oem-block {
    padding: 5px;
    margin-top: 70px;
    font-size: 10pt;
    --details-height: 300px;
}

.app-oem-block .marque {
    position: relative;
}

.bg-oem-vendors {
    background-color: yellowgreen;
    position: absolute;
    bottom: -10px;
    left: 5px;
}

.bg-oem-count {
    background-color: #007bff;
    color: white;
    position: absolute;
    top: -5px;
    right: 5px;
}

.app-oem-block .bg-secondary {
    position: absolute;
    bottom: -10px;
    right: 5px;
}

.app-oem-block > h2 {
    display: inline-block;
}

.app-oem-block > .apps {
    margin-left: 12px;
}

.app-oem-details {
    display: flex;
    overflow: hidden;
    max-height: var(--details-height);
    box-shadow: inset 2px 2px 20px 0 #656565;
    border-radius: 10px;
    padding: 8px;
}

.app-oem-details > img {
    height: 150px;
    margin-left: auto;
}

.app-oem-details .ReactTable {
    flex-grow: 1;
    margin-left: 12px;
}

.app-oem-details > ul {
    overflow-x: hidden;
    overflow-y: auto;
}

.app-oem-details.app-oem-details-enter-active > ul,
.app-oem-details.app-oem-details-exit-active > ul {
    overflow-y: hidden;
}

.app-oem-block .tooltip-arrow:after {
    content: "";
    display: table;
    border: 10px solid;
    border-top: none;
    border-color: darkgray transparent;
    position: absolute;
    bottom: -51px;
    left: 25px;
    pointer-events: none;
}

.app-oem-block .single-app .tooltip-arrow:after {
    bottom: -25px;
}

.app-oem-details .btn-link {
    text-align: left;
}

.app-oem-details .btn.has-offers {
    font-weight: bold;
    text-decoration: underline;
}

.app-oem-details-enter {
    max-height: 0;
}

.app-oem-details-enter-active {
    max-height: var(--details-height);
    transition: max-height 300ms;
}

.app-oem-details-exit {
    max-height: var(--details-height);
}

.app-oem-details-exit-active {
    max-height: 10px;
    transition: max-height 300ms;
}

.filter-option {
    padding: 8px;
    cursor: pointer;
}

.filter-option.option--is-focused {
    background-color: rgb(222, 235, 255);
}

.filter-option > img {
    margin-right: 8px;
}

.ReactTable .rt-resizer {
    z-index: auto;
}

.page-item.active .page-link {
    z-index: auto;
}

.country-flag {
    margin-right: 2px;
}

.attributes-form input {
    margin-right: 5px;
    vertical-align: middle;
}

.appoemnart.d-flex ul {
    list-style-type: disc;
    padding-left: 20px;
    overflow: auto;
}

.appoemnart > div {
    margin-left: 8px;
}

.appoemnart ul.item-notes {
    list-style-type: circle;
}

.appoemnart .btn-link {
    padding: 0;
    color: black;
}

.appoemnart > .illustration {
    position: initial;
    margin-left: auto;
    margin-right: 5px;
    align-self: center;
    cursor: pointer;
}

.app-oem-block .wizard-navigation {
    margin-bottom: 10px;
    margin-top: 10px;
}

.app-oem-block .wizard-navigation .nav-link {
    padding: 0.2rem 1rem;
}

.app-oem-block .wizard-navigation > .moving-tab {
    background-color: #03a9f47d;
    box-shadow: 0 16px 26px -10px #03a9f4, 0 4px 25px 0 rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(244, 67, 54, 0.2);
    padding: 8px;
}

.appoemnart.d-flex .list-steps {
    list-style-type: decimal;
}

.slide ul.list-steps {
    list-style-type: decimal;
    padding-left: 2em;
}

.slide ul.list-procedures {
    list-style-type: disc;
    padding-left: 2em;
}

.battery-replacement-procedures {
    padding: 1em;
}

.battery-replacement-procedures img {
    max-width: 700px;
}
