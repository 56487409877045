.slide-wrapper > .apps-widget {
    gap: 2em;
    margin: 20px;
}

.widget-catalog-hdi > .marque  {
    display: inline-flex;
    flex-direction: column;
    flex-basis: 0;
}

.widget-catalog-hdi > .marque > img {
    width: 180px;
    height: 180px;
}

.widget-catalog-hdi > .marque > .app-label {
    display: inline;
}